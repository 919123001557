
.download-btn{
    padding: 4px 8px !important;
    white-space: unset;
    ::v-deep .v-btn__content{
        width: 100%;
        max-width: 100%;
        height: 48px;
        >.download-btn__tit{
            display: inline-block;
            max-height: 100%;
            width: calc(100% - 18px);   
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;         
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .download-btn{
        padding: 8px 16px !important;
        ::v-deep .v-btn__content{
            height: 57px;
            >.download-btn__tit{
                width: calc(100% - 20px);        
            }
        }
    }

    .link-item_btn-wrap{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            border-radius: 16px;
        }
        .v-btn.view-btn{
            position: absolute;
            top: calc(50% + 20px);
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            border-color: #fff;
            color: #fff;
            .icon-right-arrow-black{
                background-image: url(/images/icon/icon-right-arrow.svg);
            }
        }
    }
    .link-item_btn-wrap:hover{
        &::after{
            opacity: 1;
            visibility: visible;
        }
        .v-btn{
            top: 50%;
            opacity: 1;
            visibility: visible;
        }
    }
    .v-btn.download-btn{
        height: 56px;
    }
}
@media (min-width:1200px){
}

